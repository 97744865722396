import Link from "next/link"
import { useTranslations } from "next-intl"

import { Button } from "@/components/ui/components"

import { AddToBaskedWithQuantityInput, AddToBaskedWithQuantityInputProps } from "./AddToBasketWithQuantityInput"

interface AddToBasketWithQuantityInputOptionalProps extends AddToBaskedWithQuantityInputProps {
  isoLocale: string
  isB2BCustomer: boolean
  showPrice: boolean
  needsPriceQuotation: boolean
  needsDeliveryQuotation: boolean
  forceRequestPriceLabel?: boolean
  priceRequestHref: string
}

export function AddToBasketWithQuantityInputOptional({
  isoLocale,
  isB2BCustomer,
  showPrice,
  needsPriceQuotation,
  needsDeliveryQuotation,
  forceRequestPriceLabel,
  priceRequestHref,
  prices,
  ...restProps
}: AddToBasketWithQuantityInputOptionalProps) {
  const t = useTranslations("storefront")

  return needsDeliveryQuotation ? (
    <>
      <Button
        className={""}
        aria-label={forceRequestPriceLabel ? t("product.request_price") : t("product.request_delivery")}
        size={"productCard"}
      >
        <Link prefetch={false} href={priceRequestHref}>
          {forceRequestPriceLabel ? t("product.request_price") : t("product.request_delivery")}
        </Link>
      </Button>
    </>
  ) : (
    <>
      {showPrice && prices && (
        <AddToBaskedWithQuantityInput
          isoLocale={isoLocale}
          isB2BCustomer={isB2BCustomer}
          prices={prices}
          {...restProps}
        />
      )}

      {!(showPrice && prices) && needsPriceQuotation && (
        <Button className={""} aria-label={t("product.request_price")} size={"productCard"}>
          <Link prefetch={false} href={priceRequestHref}>
            {t("product.request_price")}
          </Link>
        </Button>
      )}
    </>
  )
}
