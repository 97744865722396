"use client"

import { useEffect, useLayoutEffect, useState } from "react"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { AlignLeftIcon, SearchIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { BasketData } from "@/lib/intershop/interfaces/basket/basket"
import { UserData } from "@/lib/intershop/interfaces/user/user"
import { ApplicationSettings } from "@/lib/settings/application-settings"
import { Category } from "@/lib/storefront/category/category"
import { cn } from "@/lib/utils"
import { MultilinkStoryblok, UbHeaderLinkStoryblok, UbHeaderStoryblok } from "@/components/storyblok/component-types"
import HeaderLogo from "@/components/storyblok/header/HeaderLogo"
import StoryblokAccountPopupClient from "@/components/storyblok/header/StoryblokAccountPopupClient"
import StoryblokMenuBar from "@/components/storyblok/header/StoryblokMenuBar"
import StoryblokPrivateCustomerToggle from "@/components/storyblok/header/StoryblokPrivateCustomerToggle"
import StoryblokSearchBox from "@/components/storyblok/header/StoryblokSearchBox"
import StoryblokServicePhone from "@/components/storyblok/header/StoryblokServicePhone"
import StoryblokShoppingBasketPopup from "@/components/storyblok/header/StoryblokShoppingBasketPopup"
import StoryblokSwissLocaleSwitch from "@/components/storyblok/header/StoryblokSwissLocaleSwitch"
import StoryblokValueProposition from "@/components/storyblok/header/StoryblokValueProposition"

export interface UbHeaderLinkStoryblokExtended extends UbHeaderLinkStoryblok {
  multilink?: MultilinkStoryblok
  label?: string
}

interface UBHeaderProps {
  blok: UbHeaderStoryblok
  applicationSettings: ApplicationSettings
  categories: Category[]
  navigation: UbHeaderLinkStoryblokExtended[]
  userData?: UserData
  isB2BCustomer: boolean
  basket?: BasketData
  sbLocale: string
  isoLocale: string
}

export default function UBHeaderClient({
  blok,
  applicationSettings,
  categories,
  navigation,
  userData,
  isB2BCustomer,
  basket,
  sbLocale,
  isoLocale,
  ...restProps
}: UBHeaderProps) {
  const [isSticky, setIsSticky] = useState(false)
  const [prevScrollPos, setPrevScrollPos] = useState(0)

  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false)
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false)

  const { setIsB2BCustomer, setBasketData } = useUbStorefrontState()

  const t = useTranslations("storefront")

  useLayoutEffect(() => {
    setIsB2BCustomer(isB2BCustomer)
  }, [setIsB2BCustomer, isB2BCustomer])

  const STICKY_RANGE = 10

  useEffect(() => {
    setIsSticky(window.scrollY > STICKY_RANGE)

    if (basket) {
      // if no basket exists, then we treat is as empty
      setBasketData(basket)
    }
  }, [basket, setBasketData])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY
      setIsSticky(currentScrollPos > STICKY_RANGE)
      setPrevScrollPos(currentScrollPos)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [prevScrollPos])

  const onMobileMenuClick = () => {
    const localIsMobileMenuVisible = !isMobileMenuVisible
    setIsMobileMenuVisible(localIsMobileMenuVisible)
    document.body.style.overflowY = localIsMobileMenuVisible ? "hidden" : "visible"
  }

  return (
    /*Why w-screen? See:
     https://github.com/radix-ui/primitives/issues/1496 and
     https://www.reddit.com/r/reactjs/comments/1fjcwkh/comment/lnt3a0j/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button*/
    <div
      className={cn("fixed z-[151] w-screen bg-white", {
        "fixed z-[151]": isSticky,
        "h-full": isMobileMenuVisible,
      })}
    >
      <div
        className={cn(
          "mx-auto flex h-14 max-w-7xl items-start border-b border-gray-200 px-4 py-2 sm:px-6 lg:h-[90px] lg:border-0 lg:px-8",
          {
            "hidden lg:block": isMobileSearchVisible,
          }
        )}
      >
        <div className="flex w-full items-center gap-4 lg:items-start">
          <HeaderLogo logo={blok.logo} subtext={blok.subtext} sbLocale={sbLocale} />
          <div className={cn("flex grow flex-col justify-center gap-2")}>
            <div className={"hidden h-7 flex-row border-b border-gray-200 transition-all lg:flex"}>
              <div className={"flex w-full flex-row items-center justify-between gap-1 pb-1"}>
                <div className="mr-2 flex grow flex-row items-center justify-between gap-2">
                  {isB2BCustomer ? (
                    <>
                      {blok.benefit_b2b_1 && (
                        <StoryblokValueProposition text={blok.benefit_b2b_1} link={blok.benefit_link_b2b_1?.url} />
                      )}
                      {blok.benefit_b2b_2 && (
                        <StoryblokValueProposition
                          text={blok.benefit_b2b_2}
                          link={blok.benefit_link_b2b_2?.url}
                          className={"hidden lg:flex"}
                        />
                      )}
                      {blok.benefit_b2b_3 && (
                        <StoryblokValueProposition
                          text={blok.benefit_b2b_3}
                          link={blok.benefit_link_b2b_3?.url}
                          className="hidden xl:flex"
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {blok.benefit_b2c_1 && (
                        <StoryblokValueProposition text={blok.benefit_b2c_1} link={blok.benefit_link_b2c_1?.url} />
                      )}
                      {blok.benefit_b2c_2 && (
                        <StoryblokValueProposition
                          text={blok.benefit_b2c_2}
                          link={blok.benefit_link_b2c_2?.url}
                          className={"hidden lg:flex"}
                        />
                      )}
                      {blok.benefit_b2c_3 && (
                        <StoryblokValueProposition
                          text={blok.benefit_b2c_3}
                          link={blok.benefit_link_b2c_3?.url}
                          className="hidden xl:flex"
                        />
                      )}
                    </>
                  )}
                </div>
                <div className={"flex items-center justify-between gap-1 lg:min-w-96 xl:gap-2"}>
                  <StoryblokServicePhone blok={blok} sbLocale={sbLocale} />
                  <StoryblokPrivateCustomerToggle isB2BCustomer={isB2BCustomer} userData={userData} />
                </div>
              </div>
            </div>
            <div className="flex h-9 flex-row items-center justify-end gap-4">
              <div className="hidden size-full grow lg:block">
                <StoryblokSearchBox isoLocale={isoLocale} {...restProps} />
              </div>

              <button
                aria-label={t("header.search_placeholder")}
                onClick={() => setIsMobileSearchVisible(true)}
                className="flex h-full w-10 flex-row items-center justify-center gap-2 rounded px-2 font-semibold text-gray-600 hover:bg-gray-100 lg:hidden lg:w-80"
              >
                <SearchIcon className={"h-[20px] text-black"} />
              </button>
              <div className={"flex items-center justify-between gap-1 lg:min-w-96 xl:gap-2"}>
                <StoryblokAccountPopupClient blok={blok} userData={userData} />
                <StoryblokShoppingBasketPopup
                  isoLocale={isoLocale}
                  blok={blok}
                  applicationSettings={applicationSettings}
                  isB2B={isB2BCustomer}
                  userData={userData}
                />
                <StoryblokSwissLocaleSwitch isoLocale={isoLocale} {...restProps} />
              </div>
              <button
                aria-label={t("header.menu")}
                onClick={onMobileMenuClick}
                className="flex h-full w-10 flex-row items-center justify-center gap-2 rounded px-2 font-semibold text-gray-600 hover:bg-gray-100 lg:hidden lg:w-80"
              >
                <AlignLeftIcon className={"h-[20px] text-black"} />
              </button>
            </div>
          </div>
        </div>
      </div>
      {isMobileSearchVisible && (
        <div className="p-3.5 lg:hidden">
          <StoryblokSearchBox isoLocale={isoLocale} onClose={() => setIsMobileSearchVisible(false)} {...restProps} />
        </div>
      )}

      <StoryblokMenuBar
        isB2BCustomer={isB2BCustomer}
        blok={blok}
        categories={categories}
        navigation={navigation}
        isMobileVisible={isMobileMenuVisible}
        userData={userData}
        sbLocale={sbLocale}
      />
    </div>
  )
}
