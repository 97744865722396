"use client"

import React, { useMemo } from "react"
import Link from "next/link"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { storyblokEditable } from "@storyblok/react/rsc"

import { CheckoutContext } from "@/lib/storefront/checkout/checkout-service"
import { Input, Label, Textarea } from "@/components/ui/components"
import { AddressCard } from "@/components/addresses/AddressCard"
import BasicCard from "@/components/common/BasicCard"
import {
  UbCheckoutAdditionalBasketDataStoryblok,
  UbCheckoutReviewAddressesStoryblok,
} from "@/components/storyblok/component-types"

interface CheckoutReviewAddressesClientProps {
  blok: UbCheckoutReviewAddressesStoryblok
  checkoutContext: CheckoutContext
}

export default function CheckoutReviewAddressesClient(props: CheckoutReviewAddressesClientProps) {
  const checkoutContext = props.checkoutContext

  return (
    <div {...storyblokEditable(props.blok)} className={"flex w-full flex-col gap-12"}>
      <BasicCard className={"sticky top-[7.5rem] min-h-48 lg:top-[8.5rem]"}>
        <h2 className={"text-lg font-bold"}>Adressen</h2>
        <div className={"flex w-full flex-col justify-between gap-12 sm:flex-row"}>
          <div className={"flex w-full flex-row justify-between gap-2"}>
            <AddressCard address={checkoutContext.addresses[0]} />
            <Link className={"text-sm"} prefetch={false} href={"/basket/checkout/addresses"}>
              Bearbeiten
            </Link>
          </div>
          <div className={"flex w-full flex-row justify-between gap-2"}>
            <AddressCard address={checkoutContext.addresses[1]} />
            <Link className={"text-sm"} prefetch={false} href={"/basket/checkout/addresses"}>
              Bearbeiten
            </Link>
          </div>
        </div>
      </BasicCard>
    </div>
  )
}
