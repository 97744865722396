"use client"

import React, { Suspense, useMemo } from "react"
import Image from "next/image"
import Link from "next/link"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { storyblokEditable } from "@storyblok/react/rsc"

import { CheckoutContext } from "@/lib/storefront/checkout/checkout-service"
import BasicCard from "@/components/common/BasicCard"
import {
  UbCheckoutReviewPaymentStoryblok,
  UbPaymentMethodContentStoryblok,
} from "@/components/storyblok/component-types"

interface CheckoutReviewPaymentProps {
  blok: UbCheckoutReviewPaymentStoryblok
  checkoutContext: CheckoutContext
}

export default function CheckoutReviewPayment({ blok, checkoutContext }: CheckoutReviewPaymentProps) {
  const { currentPayment } = useUbStorefrontState()

  const payment = useMemo(() => {
    if (currentPayment && currentPayment.length) {
      return currentPayment[0]
    }
  }, [currentPayment])

  const paymentContent: UbPaymentMethodContentStoryblok | undefined = useMemo(() => {
    if (blok) {
      const contentList = blok?.payment_method_content?.[0]?.reference?.content?.body

      if (contentList) {
        return contentList.find((value: UbPaymentMethodContentStoryblok) => {
          return value.identifier === payment?.paymentMethod
        })
      }

      //console.dir(content)
    }
    return undefined
  }, [blok, payment])

  return (
    <div {...storyblokEditable(blok)} className={"flex w-full flex-col gap-2"}>
      {checkoutContext.userData && (
        <BasicCard className={"sticky top-[7.5rem] min-h-28 lg:top-[8.5rem]"}>
          <h2 className={"text-lg font-bold"}>Zahlungsart</h2>
          <div className={"flex w-full flex-row justify-between gap-2"}>
            <Suspense fallback={<div>...</div>}>
              {payment?.paymentMethod ? (
                <div className={"flex flex-col gap-2"}>
                  {paymentContent && (
                    <div className={"flex flex-col gap-2"}>
                      <div className={"flex flex-col justify-between gap-2"}>
                        <p>{paymentContent.title}</p>
                        <div className={"relative h-9 w-auto"}>
                          <Image
                            className={"object-contain object-left"}
                            fill={true}
                            alt={paymentContent.title ?? ""}
                            src={paymentContent?.logos?.[0]?.filename ?? ""}
                          />
                        </div>
                      </div>
                      {/*<p>{paymentContent.description}</p>*/}
                    </div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </Suspense>
            <Link className={"text-sm"} prefetch={false} href={"/basket/checkout/payment"}>
              Bearbeiten
            </Link>
          </div>
        </BasicCard>
      )}
    </div>
  )
}
