"use client"

import React, { useMemo, useState } from "react"
import { addArticleToCurrentBasketAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { ShoppingCart } from "lucide-react"

import { Button, Input } from "@/components/ui/components"
import { UbBasketQuickOrderStoryblok } from "@/components/storyblok/component-types"

interface BasketQuickOrderProps {
  blok: UbBasketQuickOrderStoryblok
}

export default function BasketQuickOrder(props: BasketQuickOrderProps) {
  const [productId, setProductId] = useState<string>("")
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const { basketData, basketLineItems, setBasketData, setIsLoading } = useUbStorefrontState()

  const hasLineItems = useMemo(() => {
    return (basketData?.data?.totalProductQuantity ?? 0) > 0 && basketLineItems && basketLineItems.length > 0
  }, [basketData?.data?.totalProductQuantity, basketLineItems])

  function updateBasketData(productId: string) {
    console.log("adding product: "+productId)
    setIsLoading(true)
    if (productId) {
      addArticleToCurrentBasketAction(productId, 1).then((result) => {
        setBasketData(result.basketData)
        if (result.result === "NOK") {
          setErrorMessage(result.message)
        }
        setIsLoading(false)
      })
    }
  }

  const handleChange = (event) => {
    const { value } = event.target
    setProductId(value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrorMessage(undefined)
    if (productId && productId !== "") {
      let effectiveProductId=productId
      if(productId.charAt(0)==="A" || productId.charAt(0)==="a"){
        effectiveProductId = productId.substring(1);
      }
      updateBasketData(effectiveProductId)
    }
  }

  return (
    <div>
      {(hasLineItems || props.blok.showWhenBasketIsEmpty) && (
        <div className={"min-h-20"}>
          <div className={"flex flex-col gap-2"}>
            <h2 className={"text-lg font-bold"}>{props.blok["label_quick_order"]}</h2>
            <form className={"flex flex-row items-center gap-2"} onSubmit={handleSubmit}>
              <Input type="text" name="productId" value={productId} onChange={handleChange} />

              <Button size={"productCardIcon"} onClick={handleSubmit}>
                <ShoppingCart className={"size-4"} />
              </Button>
            </form>
            {errorMessage && <p className={"text-xs text-red-500"}>{errorMessage}</p>}
          </div>
        </div>
      )}
    </div>
  )
}
