"use client"

import { useMemo } from "react"
import Image from "next/image"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { ClipboardListIcon, ShoppingCartIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { formatPrice } from "@/lib/helper"
import { UserData } from "@/lib/intershop/interfaces/user/user"
import { ApplicationSettings } from "@/lib/settings/application-settings"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/components"
import { UbHeaderStoryblok } from "@/components/storyblok/component-types"

interface StoryblokShoppingBasketPopupProps {
  blok: UbHeaderStoryblok
  applicationSettings: ApplicationSettings
  isB2B: boolean
  userData?: UserData
  isoLocale: string
}

export default function StoryblokShoppingBasketPopup({
  blok,
  applicationSettings,
  isB2B,
  userData,
  isoLocale,
}: StoryblokShoppingBasketPopupProps) {
  const t = useTranslations("storefront")
  const { basketData } = useUbStorefrontState()

  //const basketData: BasketData = basket?.data

  const totalProductQuantity: number | undefined = useMemo(() => {
    return basketData?.data?.totalProductQuantity
  }, [basketData])

  const itemTotal = basketData?.data?.totals?.grandTotal?.net?.value ?? 0
  const taxTotal = basketData?.data?.totals?.grandTotal?.tax?.value ?? 0
  const shippingTotal = basketData?.data?.totals?.shippingTotal?.net?.value ?? 0
  const ecoTaxTotal: number = basketData?.ecoTaxTotal ?? 0

  const totalSum = itemTotal + taxTotal + shippingTotal

  const effectiveBasketLink = useMemo(() => {
    return applicationSettings.basketLink && applicationSettings.basketLink !== ""
      ? applicationSettings.basketLink
      : (blok.basket_url?.cached_url ?? "#")
  }, [blok, applicationSettings])

  return (
    <Popover>
      <PopoverTrigger
        aria-label={t("common.shopping_basket")}
        className="flex h-full w-10 flex-row items-center justify-end gap-2 rounded pl-2 font-semibold text-gray-600 lg:w-80"
      >
        <p className="hidden whitespace-nowrap lg:block">{t("common.shopping_basket")}</p>
        <div>
          <span className="absolute -mt-2 rounded-xl bg-primary px-1 text-xs font-thin text-white">
            {totalProductQuantity ?? ""}
          </span>
          <ShoppingCartIcon className={"h-[20px] text-black"} />
        </div>
      </PopoverTrigger>
      <PopoverContent className="flex w-[260px] flex-col gap-y-4 rounded-none border-black p-4">
        <div className="flex w-full items-center justify-between">
          <Image src="/img/baer_transparent.png" alt="Logo" width={40} height={40} />
          <span className="text-lg font-bold">
            {totalProductQuantity ?? ""} {t(`common.${totalProductQuantity === 1 ? "item" : "items"}`)}
          </span>
        </div>

        {basketData && totalProductQuantity && totalProductQuantity > 0 ? (
          <div className="grid grid-cols-2 text-xs">
            {basketData.data?.totals?.grandTotal?.net && (
              <>
                <span className={"text-nowrap"}>{t("header.shopping_basket.total_positions_net")}</span>
                <span className="text-right">
                  {/*// TODO: use global locale - not yet implemented*/}
                  {formatPrice(
                    isoLocale,
                    basketData.data?.totals?.grandTotal.net.value,
                    basketData.data?.totals?.grandTotal.net.currency
                  )}
                </span>
              </>
            )}
            {basketData.data?.totals?.grandTotal?.tax && (
              <>
                <span className={"text-nowrap"}>{blok.tax_hint}</span>
                <span className="text-right">
                  {/*// TODO: use global locale - not yet implemented*/}
                  {formatPrice(
                    isoLocale,
                    basketData.data?.totals?.grandTotal.tax?.value,
                    basketData.data?.totals?.grandTotal.tax?.currency
                  )}
                </span>
              </>
            )}

            {basketData.data?.totals?.grandTotal?.tax && (
              <>
                <span className={"text-nowrap"}>{t("header.shopping_basket.shipping")}</span>
                <span className="text-right">
                  {/*// TODO: use global locale - not yet implemented*/}

                  {basketData.data?.totals?.shippingTotal !== undefined ? (
                    <>
                      {formatPrice(
                        isoLocale,
                        basketData.data?.totals?.shippingTotal?.net?.value,
                        basketData.data?.totals?.shippingTotal?.net?.currency
                      )}
                    </>
                  ) : (
                    <>{formatPrice(isoLocale, 0.0, basketData?.data?.totals?.grandTotal?.net?.currency)}</>
                  )}
                </span>
              </>
            )}

            <span className="mt-2 text-nowrap font-bold">{t("header.shopping_basket.total_gross")}</span>
            {/*// TODO: use global locale - not yet implemented*/}
            <span className="mt-2 text-right font-bold">
              {formatPrice(isoLocale, totalSum, basketData?.data?.totals?.grandTotal?.gross?.currency)}
            </span>

            {ecoTaxTotal > 0 && (
              <>
                <span className={"text-nowrap"}>{t("header.shopping_basket.eco_tax")}</span>
                <span className="text-right">
                  {<>{formatPrice(isoLocale, ecoTaxTotal, basketData?.data?.totals?.grandTotal?.net?.currency)}</>}
                </span>
              </>
            )}

            <a
              href={effectiveBasketLink}
              className="col-span-2 mt-7 rounded bg-primary py-2 text-center font-bold uppercase text-white hover:bg-primary-hover"
            >
              {t("header.shopping_basket.to_cart")}
            </a>
          </div>
        ) : (
          <>
            <span>{t("header.empty_shopping_basket")}</span>
            <a href={blok.quick_order_url?.cached_url} className="flex items-center text-sm">
              <ClipboardListIcon className="mr-2.5 text-primary"></ClipboardListIcon>
              <span>{t("header.quick_order")}</span>
            </a>

            <p className="text-xs">
              {t(
                `header.pricing_description.${(userData && userData.customerType == "SMB") || (!userData && isB2B) ? "b2b" : "b2c"}`
              )}
            </p>
          </>
        )}
      </PopoverContent>
    </Popover>
  )
}
