export function generatePageTypeTrackingData(isAnonymous: boolean, isB2B: boolean, pageType: string) {
  return {
    user: {
      loginStatus: isAnonymous ? "logged-out" : "logged-in",
      type: isB2B ? "B2B" : "B2C",
    },
    page: {
      type: pageType,
    },
  }
}
