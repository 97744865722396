"use client"

import { PropsWithChildren, useMemo } from "react"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { UbContextDependentStoryblok } from "@/components/storyblok/component-types"

interface UbContextDependentClientProps extends PropsWithChildren {
  blok: UbContextDependentStoryblok
  showContent: boolean
  matchAll: boolean
}

export default function UbContextDependentClient({
  blok,
  showContent,
  matchAll,
  children,
}: UbContextDependentClientProps) {
  const basketNotEmpty: boolean = blok.basketNotEmpty || false
  const basketEmpty: boolean = blok.basketEmpty || false

  const { basketData, basketLineItems } = useUbStorefrontState()

  const hasLineItems = useMemo(() => {
    const result = (basketData?.data?.totalProductQuantity ?? 0) > 0 && basketLineItems && basketLineItems.length > 0

    return result
  }, [basketData?.data?.totalProductQuantity, basketLineItems])

  function basketEmptyCheck() {
    return basketEmpty ? !hasLineItems : matchAll
  }

  function basketNotEmptyCheck() {
    return basketNotEmpty ? hasLineItems : matchAll
  }

  let showContentClient = matchAll
    ? showContent || (basketEmptyCheck() && basketNotEmptyCheck())
    : showContent || basketEmptyCheck() || basketNotEmptyCheck()

  return <>{showContentClient && children && <>{children}</>}</>
}
