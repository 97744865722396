import { PaymentMethodROV1 } from "@ub/basket-client"

interface PayoneEpsBankGroupSelectBoxProps {
  paymentMethod: PaymentMethodROV1 | undefined
  isoLocale: string
}

export default function PayoneEpsBankGroupSelectBox(props: PayoneEpsBankGroupSelectBoxProps) {
  const params = (props.paymentMethod?.hostedPaymentPageParameters ?? []).sort((a, b) =>
    ((a.value as unknown as string) ?? "").localeCompare((b.value as unknown as string) ?? "", props.isoLocale)
  )

  return (
    <select name={"bankGroupCode"}>
      <option value={""}></option>
      {params?.map((param) => {
        return (
          <option key={param.name} value={param.name}>
            {param.value as unknown as string}
          </option>
        )
      })}
    </select>
  )
}
