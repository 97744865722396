"use client"

import React, { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "next/navigation"
import { DEFAULT_SEARCH_RESULT_GROUP_SIZE, DEFAULT_SEARCH_RESULT_VISIBLE_GROUPS } from "@/constants/ui"
import { useUbPdpState } from "@/store/UbpdpState"
import { ChevronLeftIcon, ChevronRightIcon, DoubleArrowLeftIcon, DoubleArrowRightIcon } from "@radix-ui/react-icons"
import { ProductPriceContainerCtnrROV1, ProductPriceContainerRO } from "@ub/pricing-client"
import { useTranslations } from "next-intl"

import { generateProductLink } from "@/lib/link-utils/link-utils"
import { extractCardPriceForSingleArticlePriceData, generateSkuParams } from "@/lib/price-utils"
import { PdpContext } from "@/lib/storefront/product/pdp-service"
import { ReducedProductData, VariationProductData } from "@/lib/storefront/product/product"
import { getCurrentFilterValue, showProductPrice } from "@/lib/storefront/product/productHelper"
import { Button } from "@/components/ui/components"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/components/tooltip"
import { cn } from "@/components/ui/helpers/utils"
import ErrorBoundary from "@/components/layout/ErrorBoundary"
import { AddToBasketWithQuantityInputOptional } from "@/components/product/pdp/buybox/AddToBasketWithQuantityInputOptional"
import { DropdownFilterSelector } from "@/components/product/pdp/filter/DropdownFilterSelector"
import { VariantPriceSortDirection } from "@/components/product/pdp/filter/VariantPriceSortDirection"
import { ProductDetailProductListPrice } from "@/components/product/pdp/ProductDetailProductListPrice"
import { UseFilteredVariantsHook } from "@/components/product/pdp/UseFilteredVariantsHook"
import { getPaginationInfo, paginateArray } from "@/components/search-and-category/pagination-info"
import ImageWithFallback from "@/components/storyblok/common/ImageWithFallback"
import { UbProductDetailViewStoryblok } from "@/components/storyblok/component-types"

type ProductDetailVariantTableProps = {
  pdpContext: PdpContext
  blok?: UbProductDetailViewStoryblok
  priceRequestLink: string
}

function VariantCard(props: {
  skuPriceMap: Record<string, ProductPriceContainerRO | undefined> | undefined
  variant: VariationProductData
  article: ReducedProductData
  pdpContext: PdpContext
  priceRequestLink: string
}) {
  const t = useTranslations("storefront")

  const searchParams = useSearchParams()
  const variantLink = generateProductLink(
    props.variant.title,
    props.variant.sku,
    searchParams,
    props.pdpContext.sbLocale
  )

  const prices = props.skuPriceMap?.[props.variant.sku]
  const cardPrices = extractCardPriceForSingleArticlePriceData(
    props.pdpContext.isoLocale,
    props.variant.sku,
    props.pdpContext.isB2B,
    prices
  )
  const { showPrice, needsPriceQuotation } = showProductPrice(props.article, cardPrices)
  const needsDeliveryQuotation = props.article?.availability === false
  const priceRequestHref =
    (needsPriceQuotation || needsDeliveryQuotation) && props.article?.id
      ? props.priceRequestLink + props.article.id.toString()
      : ""

  return (
    <div
      className={
        "grid grid-cols-1 gap-2 rounded-sm border border-gray-200 bg-white p-2 hover:border-primary sm:grid-cols-2 sm:pr-6"
      }
    >
      <a className={"block cursor-pointer"} href={variantLink}>
        <div className={"flex flex-row justify-start gap-2"}>
          <div>
            <TooltipProvider delayDuration={50} skipDelayDuration={100}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <ImageWithFallback
                    className={"aspect-square h-20 w-auto object-contain"}
                    src={props.variant.imageUrl}
                    width={80}
                    height={80}
                    alt={props.variant.title}
                  />
                </TooltipTrigger>
                <TooltipContent side={"right"} className={"rounded-sm border border-gray-200 bg-white"}>
                  <ImageWithFallback
                    className={"aspect-square h-48 w-auto object-contain"}
                    src={props.variant.imageUrl}
                    width={192}
                    height={192}
                    alt={props.variant.title}
                  />
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          <div className={"items-left flex flex-col gap-2"}>
            <p className={"text-sm font-bold"}>{props.variant.title}</p>
            <p className={"text-sm"}>{props.variant.description}</p>
            <p className={"text-xs"}>
              {t("product.article_number")}:{props.variant.sku}
            </p>
          </div>
        </div>
      </a>
      <div
        className={"flex w-full flex-col items-end justify-end gap-2 sm:justify-between md:flex-row md:items-center"}
      >
        <a href={variantLink} className={"flex min-w-32 flex-col gap-2"}>
          <ProductDetailProductListPrice
            pdpContext={props.pdpContext}
            cardPrices={cardPrices}
            productData={props.article}
            showPrice={showPrice}
          />

          {/*<p className={"font-bold"}>ab 722,90 €, ab 2 Stück</p>*/}
        </a>
        <div className={"flex w-auto flex-row items-center justify-end gap-2"}>
          <AddToBasketWithQuantityInputOptional
            isoLocale={props.pdpContext.isoLocale}
            isB2BCustomer={props.pdpContext.isB2B}
            localState={true}
            compact={true}
            productSku={props.variant?.sku!}
            article={props.article}
            prices={props.skuPriceMap?.[props.variant.sku]}
            minOrderQuantity={props.article?.minOrderQuantity}
            orderQuantitySteps={props.article?.stepOrderQuantity}
            showPrice={showPrice}
            needsPriceQuotation={needsPriceQuotation}
            needsDeliveryQuotation={needsDeliveryQuotation}
            priceRequestHref={priceRequestHref}
          />
        </div>
      </div>
    </div>
  )
}

export default function ProductDetailVariantTable(props: ProductDetailVariantTableProps) {
  const masterProduct = props.pdpContext.productAndArticle.masterProduct
  const article = props.pdpContext.productAndArticle.article
  const [page, setPage] = useState(1)
  const { useClientSelectedFilterOptions } = useUbPdpState()

  const [productsAndPrices, setProductsAndPrices] = useState<
    { products: (ReducedProductData | undefined)[]; prices: ProductPriceContainerCtnrROV1 | undefined } | undefined
  >(undefined)

  const t = useTranslations("storefront")

  const pageSize = 10
  const filteredVariants = UseFilteredVariantsHook(masterProduct).filteredVariants

  const paginatedFilteredVariants = useMemo(() => {
    return paginateArray(filteredVariants, page, pageSize)
  }, [filteredVariants, page])

  const hitcount = useMemo(() => {
    return filteredVariants.length
  }, [filteredVariants])

  const lastPage = useMemo(() => {
    return Math.ceil(hitcount / pageSize)
  }, [hitcount])

  const paginationInfo = useMemo(() => {
    return getPaginationInfo(DEFAULT_SEARCH_RESULT_GROUP_SIZE, page, lastPage, DEFAULT_SEARCH_RESULT_VISIBLE_GROUPS)
  }, [lastPage, page])
  const previousPage = useMemo(() => {
    return Math.max(page - 1, 1)
  }, [page])

  const nextPage = useMemo(() => {
    return Math.min(page + 1, lastPage)
  }, [lastPage, page])

  function navigateToPage(page: number) {
    setPage(page)
  }

  const skuPriceMap = useMemo(() => {
    const skuToPriceMap: Record<string, ProductPriceContainerRO | undefined> = {}
    productsAndPrices?.prices?.data?.forEach((ppc: ProductPriceContainerRO) => {
      skuToPriceMap[ppc.sku ?? ""] = ppc
      //console.dir(skuToPriceMap)
    })
    return skuToPriceMap
  }, [productsAndPrices])

  const skuProductMap = useMemo(() => {
    const skuToProductMap: Record<string, ReducedProductData> = {}

    productsAndPrices?.products?.forEach((product: ReducedProductData) => {
      skuToProductMap[product.id ?? ""] = product
    })

    return skuToProductMap
  }, [productsAndPrices?.products])

  useEffect(() => {
    if (useClientSelectedFilterOptions) {
      const allSkus: string[] = paginatedFilteredVariants.map((variant) => {
        return variant.sku
      })

      const skuParams = generateSkuParams(allSkus.slice(0, 10))

      const fetchProductsAndPrices = async () => {
        const customerNo = props.pdpContext.userData?.customerNo ?? "0"
        const fetchUrl = "/api/variants?customerNo=" + customerNo + "&sku=" + skuParams
        const result = await fetch(fetchUrl)
        const data = await result.json()
        //console.dir(data)
        setProductsAndPrices(data)
      }
      fetchProductsAndPrices()
        .then((value) => {})
        .catch(() => {
          console.log("Abort price fetching...")
        })
    }
  }, [paginatedFilteredVariants, props.pdpContext.userData?.customerNo, useClientSelectedFilterOptions])

  return (
    <ErrorBoundary>
      {useClientSelectedFilterOptions && masterProduct && filteredVariants.length > 1 && (
        <div className={"flex flex-col gap-2"}>
          <h2 className={"text-xl font-bold"}>
            {filteredVariants.length} {props.blok?.title_variants}
          </h2>
          <p className={"text-sm font-bold"}>
            {t("product.variant_overview")} {masterProduct.title}
          </p>
          <p className={"text-sm font-bold"}>{props.blok?.label_variant_helptext}</p>
          <h3 className={"text-lg font-bold"}>{t("product.filter_by")}</h3>
          <div>
            <div className={"flex flex-row gap-2 text-sm"}>
              {masterProduct &&
                masterProduct.variationAttributeValuesExtended &&
                masterProduct.variationAttributeValuesExtended.length > 0 && (
                  <div className={"flex min-w-32 flex-row flex-wrap gap-4 text-sm"}>
                    {masterProduct.variationAttributeValuesExtended.map((va) => (
                      <div key={va.variationAttributeId}>
                        <DropdownFilterSelector
                          attribute={{
                            id: va.variationAttributeId,
                            label: va.name,
                            currentValue: getCurrentFilterValue(article, va.variationAttributeId),
                          }}
                          values={va.values}
                          pdpContext={props.pdpContext}
                        />
                      </div>
                    ))}

                    <VariantPriceSortDirection />
                    <div className={"flex flex-col gap-4"}>
                      <div className={"flex flex-col gap-2 pb-2"}>
                        <span className={"font-bold text-transparent"}>Reset</span>
                        <a className={"cursor-pointer"} href={props.pdpContext.masterProductLink}>
                          <Button
                            size={"defaultNoGrow"}
                            variant={"default"}
                            className={"bg-primary py-0 font-normal text-white"}
                          >
                            {" "}
                            {t("common.reset_filters")}
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className={"flex flex-col gap-2"}>
            {filteredVariants && filteredVariants.length > 0 && (
              <>
                {paginatedFilteredVariants.map((variant) => (
                  <VariantCard
                    skuPriceMap={skuPriceMap}
                    key={variant.sku}
                    variant={variant}
                    article={skuProductMap[variant.sku]}
                    pdpContext={props.pdpContext}
                    priceRequestLink={props.priceRequestLink}
                  />
                ))}
              </>
            )}
          </div>
          {lastPage > 1 && (
            <div className={"flex w-full flex-row items-center justify-center"}>
              <div className="flex flex-row gap-x-4">
                <span
                  onClick={() => navigateToPage(1)}
                  className={cn("pt-[0.19rem]", { "pointer-events-none cursor-pointer text-gray2": page === 1 })}
                  title={t("pagination.first")}
                >
                  <DoubleArrowLeftIcon className="size-6" />
                </span>
                <span
                  onClick={() => navigateToPage(previousPage)}
                  className={cn("pt-[0.19rem]", { "pointer-events-none cursor-pointer text-gray2": page === 1 })}
                  title={t("pagination.previous")}
                >
                  <ChevronLeftIcon className="size-6" />
                </span>
                {paginationInfo.map((piItem) => (
                  <span
                    className={cn("cursor-pointer pt-[0.19rem]", {
                      "size-8 text-center rounded-full bg-primary text-white":
                        piItem.start === page && !(page === lastPage),
                      "size-8 text-center rounded-full bg-gray-200 text-gray-800":
                        piItem.start === page && page === lastPage,
                    })}
                    onClick={() => navigateToPage(piItem.start)}
                    key={"SearchResultGridPage_" + piItem.start}
                    title={
                      piItem.start === piItem.end
                        ? t("pagination.page", { page: piItem.start, total: lastPage })
                        : t("pagination.pages", {
                            page: piItem.start + "-" + piItem.end,
                            total: lastPage,
                          })
                    }
                  >
                    {piItem.start === piItem.end ? piItem.start : piItem.start + "-" + piItem.end}
                  </span>
                ))}
                <span
                  className={cn("pt-[0.19rem]", {
                    "pointer-events-none cursor-pointer text-gray2": page === lastPage,
                  })}
                  onClick={() => navigateToPage(nextPage)}
                  title={t("pagination.next")}
                >
                  <ChevronRightIcon className="size-6" />
                </span>
                <span
                  className={cn("pt-[0.19rem]", {
                    "pointer-events-none cursor-pointer text-gray2": page === lastPage,
                  })}
                  onClick={() => navigateToPage(lastPage)}
                  title={t("pagination.last")}
                >
                  <DoubleArrowRightIcon className="size-6" />
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </ErrorBoundary>
  )
}
