"use client"

import { Suspense } from "react"
import Link from "next/link"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { useTranslations } from "next-intl"

import { CheckoutContext } from "@/lib/storefront/checkout/checkout-service"
import { Button } from "@/components/ui/components"

interface CheckoutConfirmationProps {
  checkoutContext: CheckoutContext
}
export default function CheckoutConfirmation(props: CheckoutConfirmationProps) {
  const t = useTranslations("storefront")
  const { createdOrder } = useUbStorefrontState()

  return (
    <>
      <div className={"flex flex-col gap-2"}>
        <Link prefetch={false} className={"cursor-pointer"} href={"/"}>
          <Button size={"defaultNoGrow"} variant={"default"} className={"bg-primary py-0 font-normal text-white"}>
            {"Weiter Einkaufen"}
          </Button>
        </Link>

        <Suspense fallback={<div>Loading...</div>}>
          <div className={"hidden"}>{JSON.stringify(createdOrder, null, 2)}</div>
        </Suspense>
      </div>
    </>
  )
}
