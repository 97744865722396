import React from "react"
import Script from "next/script"
import { PaymentMethodROV1 } from "@ub/basket-client"
import { useTranslations } from "next-intl"

import { Button } from "@/components/ui/components"
import CreditCheckConsent from "@/components/checkout/payment/CreditCheckConsent"

interface PayoneCreditCardHostedIFramesProps {
  paymentMethod: PaymentMethodROV1 | undefined
  creditCheckConsentChecked: boolean
  onCreditCheckConsentChange: any
  showCreditCheckConsent: boolean | undefined
  creditCheckConsentText: string | undefined
  showCreditCheckConsentRequired: boolean | undefined
  creditCheckConsentRequiredText: string | undefined
}

export default function PayoneCreditCardHostedIFrames(props: PayoneCreditCardHostedIFramesProps) {
  const t = useTranslations("storefront")

  function getParameter(name: string): object | undefined {
    return props.paymentMethod?.hostedPaymentPageParameters?.find((param) => param.name === name)?.value
  }

  const request = getParameter("request")
  const isSecurityCheckCodeRequired = getParameter("isSecurityCheckCodeRequired") as unknown as boolean
  const language = getParameter("language")
  const config = (getParameter("config") as unknown as string).replace(/ } $/, `, language : ${language} } `)

  return (
    <>
      <Script
        src="https://secure.pay1.de/client-api/js/v1/payone_hosted_min.js"
        onReady={() => {
          window.iframes = eval(`new Payone.ClientApi.HostedIFrames(${config}, ${request})`)
        }}
      />

      <div className={"grid w-full grid-cols-3 gap-4"}>
        <div className="text-danger col-span-3" id="error"></div>

        <div className={"col-span-3 flex flex-col gap-2"}>
          <h2 className={"text-large font-bold"}>{t("checkout.payment.payone.creditcard.cardtype")}</h2>
          <div id="cardtype"></div>
        </div>

        <div className={"col-span-3 flex flex-col gap-2"}>
          <h2 className={"text-large font-bold"}>{t("checkout.payment.payone.creditcard.cardnumber")}</h2>
          <div id="cardpan"></div>
        </div>

        <div className={"col-span-1"}>
          <div className={"flex flex-col gap-2"}>
            <h2 className={"text-large font-bold"}>{t("checkout.payment.payone.creditcard.expirationdate")}</h2>
            <span id="cardexpiremonth"></span>
          </div>
        </div>
        <div>
          <div className={"flex flex-col gap-2"}>
            <h2 className={"text-large font-bold"}>&nbsp;</h2>
            <span id="cardexpireyear"></span>
          </div>
        </div>
        <div className={"col-span-1"}>
          {isSecurityCheckCodeRequired && (
            <div className={"flex flex-col gap-2"}>
              <h2 className={"text-large font-bold"}>{t("checkout.payment.payone.creditcard.cvc")}</h2>
              <div id="cardcvc2"></div>
            </div>
          )}
        </div>

        <div className="col-span-3">
          <CreditCheckConsent
            checked={props.creditCheckConsentChecked}
            onChange={props.onCreditCheckConsentChange}
            show={props.showCreditCheckConsent}
            text={props.creditCheckConsentText}
            showRequired={props.showCreditCheckConsentRequired}
            requiredText={props.creditCheckConsentRequiredText}
          />
          <div className="flex justify-end">
            <Button
              size={"defaultNoGrow"}
              variant={"default"}
              className={"cursor-pointer bg-primary py-0 font-normal text-white"}
              id={"payoneCreditCardCheck"}
              type={"button"}
              onClick={() => {
                if (window.iframes.isComplete()) {
                  window.iframes.creditCardCheck("payoneCreditCardCallback")
                }
              }}
            >
              {t("checkout.payment.payone.creditcard.check")}
            </Button>
          </div>
        </div>
      </div>

      <input type={"hidden"} name={"pseudocardpan"} id={"pseudocardpan"} />
      <input type={"hidden"} name={"truncatedcardpan"} id={"truncatedcardpan"} />

      <Script id="payone-hosted-iframes">{`
        function payoneCreditCardCallback(response) {
          if (response.status === "VALID") {
            document.getElementById('paymentMethodID').value = "${props.paymentMethod?.id}";
            document.getElementById('pseudocardpan').value = response.pseudocardpan;
            document.getElementById('truncatedcardpan').value = response.truncatedcardpan;
            document.querySelector('form[name="PaymentForm"]').requestSubmit();
          }
        }
      `}</Script>
    </>
  )
}
