"use client"

import React, { useEffect, useRef, useState } from "react"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslations } from "next-intl"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"

import { UserData } from "@/lib/intershop/interfaces/user/user"
import { ExtendedAddressRO } from "@/lib/storefront/addresses/address"
import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/components"
import { cn } from "@/components/ui/helpers/utils"

// settings for development purposes
const DEV_INSERT_DEFAULTS = false

const countries = ["germany", "austria", "switzerland", "france", "netherlands"]

interface EditAddressProps {
  address?: ExtendedAddressRO
  customer?: UserData
  user?: UserData
  onSave?: (address: ExtendedAddressRO, user: UserData) => void
}

const EditAddress = ({ address, customer, user, onSave }: EditAddressProps) => {
  const formRef = useRef<HTMLFormElement>(null)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    form.reset(address)
  }, [address])

  const t = useTranslations("storefront")
  const { isB2BCustomer } = useUbStorefrontState()

  const validationSchema = z.object({
    title: z.enum([t("salutation.ms"), t("salutation.mr"), t("salutation.divers")], {
      required_error: t("forms.edit_address_form.error.title"),
    }),
    honorific: z.string().max(64, t("forms.edit_address_form.error.honorific_max")).optional(),
    firstName: z
      .string({ required_error: t("forms.edit_address_form.error.first_name") })
      .min(2, t("forms.edit_address_form.error.first_name"))
      .max(64, t("forms.edit_address_form.error.first_name_max")),
    lastName: z
      .string({ required_error: t("forms.edit_address_form.error.last_name") })
      .min(2, t("forms.edit_address_form.error.last_name"))
      .max(64, t("forms.edit_address_form.error.last_name_max")),
    companyName1: isB2BCustomer
      ? z
          .string({
            required_error: t("forms.edit_address_form.error.company"),
          })
          .min(2, t("forms.edit_address_form.error.company"))
          .max(64, t("forms.edit_address_form.error.company_max"))
      : z.string().optional(),
    companyName2: z.string().max(64, t("forms.edit_address_form.error.company_max")).optional(),
    taxationID: z.string().max(64, t("forms.edit_address_form.error.taxation_id_max")).optional(),
    addressLine1: z
      .string({ required_error: t("forms.edit_address_form.error.address_line") })
      .min(2, t("forms.edit_address_form.error.address_line"))
      .max(128, t("forms.edit_address_form.error.address_line_max")),
    addressLine2: z.string().max(128, t("forms.edit_address_form.error.address_line_max")).optional(),
    postalCode: z
      .string({ required_error: t("forms.edit_address_form.error.postal_code") })
      .regex(/\d{4,5}$/, t("forms.edit_address_form.error.postal_code")),
    city: z
      .string({
        required_error: t("forms.edit_address_form.error.city"),
      })
      .min(2, t("forms.edit_address_form.error.city"))
      .max(128, t("forms.edit_address_form.error.city_max")),
    country: z.enum(
      [
        t("country.germany"),
        t("country.austria"),
        t("country.switzerland"),
        t("country.france"),
        t("country.netherlands"),
      ],
      {
        required_error: t("forms.edit_address_form.error.country"),
      }
    ),
    email: z
      .string({
        required_error: t("forms.edit_address_form.error.email"),
      })
      .email(t("forms.edit_address_form.error.email")),
    phoneBusiness: z
      .string()
      .min(2, t("forms.edit_address_form.error.phone"))
      .max(32, t("forms.edit_address_form.error.phone_max"))
      .optional(),
    invoiceToAddress: z.boolean(),
    isPreferredInvoiceToAddress: z.boolean().optional(),
    shipToAddress: z.boolean(),
    isPreferredShipToAddress: z.boolean().optional(),
  })

  type ValidationSchemaType = z.infer<typeof validationSchema>

  console.log(user?.preferredInvoiceToAddress?.id, address?.id)

  const form = useForm<ValidationSchemaType>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      title: DEV_INSERT_DEFAULTS ? "Herr" : address?.title,
      honorific: DEV_INSERT_DEFAULTS ? "jjj" : address?.honorific,
      firstName: DEV_INSERT_DEFAULTS ? "Jon" : address?.firstName,
      lastName: DEV_INSERT_DEFAULTS ? "Doe" : address?.lastName,
      companyName1: DEV_INSERT_DEFAULTS ? "Acme Company" : address?.companyName1,
      companyName2: DEV_INSERT_DEFAULTS ? "Empfang" : address?.companyName2,
      taxationID: DEV_INSERT_DEFAULTS ? "Tax" : customer?.["taxationID"],
      addressLine1: DEV_INSERT_DEFAULTS ? "Dummystr. 4" : address?.addressLine1,
      addressLine2: DEV_INSERT_DEFAULTS ? "1st Floor" : address?.addressLine2,
      postalCode: DEV_INSERT_DEFAULTS ? "08123" : address?.postalCode,
      city: DEV_INSERT_DEFAULTS ? "Berlin" : address?.city,
      country: DEV_INSERT_DEFAULTS ? "Deutschland" : address?.country,
      email: DEV_INSERT_DEFAULTS ? "acme@acme.com" : address?.email,
      phoneBusiness: DEV_INSERT_DEFAULTS ? "+49321123123" : address?.phoneBusiness,
      invoiceToAddress: DEV_INSERT_DEFAULTS ? false : address?.invoiceToAddress === true,
      isPreferredInvoiceToAddress: address && user?.preferredInvoiceToAddress?.id === address.id,
      shipToAddress: DEV_INSERT_DEFAULTS ? false : address?.shipToAddress === true,
      isPreferredShipToAddress: address && user?.preferredShipToAddress?.id === address.id,
    },
  })

  const onSubmit: SubmitHandler<ValidationSchemaType> = (data: ValidationSchemaType, event) => {
    event && event.preventDefault()
    setSaving(true)

    const newAddress = {
      ...data,
      shipFromAddress: false,
      installToAddress: false,
      serviceToAddress: false,
      preferredInvoiceToAddress: undefined,
      preferredShipToAddress: undefined,
    } as ExtendedAddressRO

    const newUser = {
      ...user,
      preferredInvoiceToAddress: data.isPreferredInvoiceToAddress ? newAddress : user?.preferredInvoiceToAddress,
      preferredShipToAddress: data.isPreferredShipToAddress ? newAddress : user?.preferredShipToAddress,
    } as UserData

    onSave && onSave(newAddress, newUser)

    // todo make data persistent via intershop API

    setSaving(false)
  }

  const styleWithoutOutline =
    "focus:ring-offset-0 focus-visible:ring-offset-0 focus:ring-0 focus:outline-0 focus-visible:outline-0 focus-visible:ring-0"
  const focusBorders = "focus:border-primary focus:border-2 focus-visible:border-primary focus-visible:border-2"
  const textFieldStyleBase = styleWithoutOutline + " " + focusBorders + " pl-4"
  const textFieldStyle = textFieldStyleBase + " pt-1.5 rounded-lg"
  const radioButtonStyle = styleWithoutOutline + " " + focusBorders + " size-5"
  const selectStyle = styleWithoutOutline + " " + focusBorders + " text-base pt-1.5 rounded-lg"
  const checkboxStyle = styleWithoutOutline + " " + focusBorders + " mr-3 size-5"

  return (
    <div
      className={cn("", {
        "pointer-events-none cursor-wait": saving,
      })}
    >
      <Form {...form}>
        <form className="flex flex-col" ref={formRef} name="EditAddressForm" onSubmit={form.handleSubmit(onSubmit)}>
          <fieldset className="flex flex-col gap-y-3">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormControl>
                    <RadioGroup className="flex flex-row" onValueChange={field.onChange}>
                      <div className="flex flex-row items-center justify-center gap-2">
                        <div className="flex flex-row items-center justify-center gap-2">
                          <RadioGroupItem
                            className={radioButtonStyle}
                            {...field}
                            id="salutationMs"
                            value={t("salutation.ms")}
                          />
                          <label htmlFor="salutationMs">{t("salutation.ms")}</label>
                        </div>
                        <div className="flex flex-row items-center justify-center gap-2">
                          <RadioGroupItem className={radioButtonStyle} {...field} id="salutationMr" />
                          <label htmlFor="salutationMr">{t("salutation.mr")}</label>
                        </div>
                        <div className="flex flex-row items-center justify-center gap-2">
                          <RadioGroupItem className={radioButtonStyle} {...field} id="salutationDivers" />
                          <label htmlFor="salutationDivers">{t("salutation.divers")}</label>
                        </div>
                      </div>
                      <span className="text-sm text-primary">*</span>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="honorific"
              render={({ field }) => (
                <FormItem className="w-40">
                  <FormControl>
                    <Input
                      className={textFieldStyle}
                      {...field}
                      placeholder={t("forms.edit_address_form.placeholder.honorific")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-row gap-x-2">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        className={textFieldStyle}
                        {...field}
                        placeholder={t("forms.edit_address_form.placeholder.first_name")}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        className={textFieldStyle}
                        {...field}
                        placeholder={t("forms.edit_address_form.placeholder.last_name")}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            {isB2BCustomer && (
              <FormField
                control={form.control}
                name="companyName1"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        className={textFieldStyle}
                        {...field}
                        placeholder={t("forms.edit_address_form.placeholder.company1") + (isB2BCustomer && "*")}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            {isB2BCustomer && (
              <div className="flex flex-row gap-x-2">
                <FormField
                  control={form.control}
                  name="companyName2"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          className={textFieldStyle}
                          {...field}
                          placeholder={t("forms.edit_address_form.placeholder.company2")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="taxationID"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          className={textFieldStyle}
                          {...field}
                          placeholder={t("forms.edit_address_form.placeholder.taxation_id")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            )}
            <FormField
              control={form.control}
              name="addressLine1"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      className={textFieldStyle}
                      {...field}
                      placeholder={t("forms.edit_address_form.placeholder.address_line_1")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="addressLine2"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      className={textFieldStyle}
                      {...field}
                      placeholder={t("forms.edit_address_form.placeholder.address_line_2")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-row gap-x-2">
              <FormField
                control={form.control}
                name="postalCode"
                render={({ field }) => (
                  <FormItem className="w-[6.8rem]">
                    <FormControl>
                      <Input
                        className={textFieldStyle}
                        {...field}
                        placeholder={t("forms.edit_address_form.placeholder.postal_code")}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <Input
                        className={textFieldStyle}
                        {...field}
                        placeholder={t("forms.edit_address_form.placeholder.city")}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="country"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Select onValueChange={field.onChange}>
                      <SelectTrigger className={selectStyle}>
                        <SelectValue
                          placeholder={
                            <span className="text-base text-muted-foreground">
                              {t("forms.edit_address_form.placeholder.country")}
                            </span>
                          }
                        />
                      </SelectTrigger>
                      <SelectContent className="z-above-all rounded-sm">
                        {countries.map((country) => (
                          <SelectItem className="cursor-pointer" value={t(`country.${country}`)} key={country}>
                            {t(`country.${country}`)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      className={textFieldStyle}
                      {...field}
                      placeholder={t("forms.edit_address_form.placeholder.email")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phoneBusiness"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      className={textFieldStyle}
                      {...field}
                      placeholder={t("forms.edit_address_form.placeholder.phone_business")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="invoiceToAddress"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="flex flex-row items-center">
                      <Checkbox
                        id="invoiceToAddress"
                        className={checkboxStyle}
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      <label className="text-base" htmlFor="invoiceToAddress">
                        {t("forms.edit_address_form.placeholder.invoice_to_address")}
                      </label>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="isPreferredInvoiceToAddress"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="flex flex-row items-center">
                      <Checkbox
                        id="isPreferredInvoiceToAddress"
                        className={checkboxStyle + " ml-6"}
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      <label className="text-base" htmlFor="isPreferredInvoiceToAddress">
                        {t("forms.edit_address_form.placeholder.preferred_invoice_to_address")}
                      </label>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="shipToAddress"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="flex flex-row items-center">
                      <Checkbox
                        id="shipToAddress"
                        className={checkboxStyle}
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      <label className="text-base" htmlFor="shipToAddress">
                        {t("forms.edit_address_form.placeholder.ship_to_address")}
                      </label>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="isPreferredShipToAddress"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="flex flex-row items-center">
                      <Checkbox
                        id="isPreferredShipToAddress"
                        className={checkboxStyle + " ml-6"}
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      <label className="text-base" htmlFor="isPreferredShipToAddress">
                        {t("forms.edit_address_form.placeholder.preferred_ship_to_address")}
                      </label>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </fieldset>
          <Button type="submit" size="lg" className="mb-6 mt-10 self-center" disabled={saving}>
            {t("forms.edit_address_form.save")}
          </Button>
        </form>
      </Form>
    </div>
  )
}

export default EditAddress
