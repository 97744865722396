"use client"

import React, { useEffect } from "react"
import { useRouter, useSearchParams } from "next/navigation"
import {
  loadBasketLineItemsAction,
  loadBasketPaymentsAction,
  loadBasketPromotionCodesAction,
  loadCurrentBasketAction,
  updateOrderAfterRedirectAction,
} from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { BasketContext } from "@/lib/storefront/basket/basket"
import { CheckoutContext } from "@/lib/storefront/checkout/checkout-service"

interface CheckoutReviewClientProps {
  checkoutContext: CheckoutContext
  basketContext: BasketContext
}

export default function CheckoutReviewClient(props: CheckoutReviewClientProps) {
  const checkoutContext = props.checkoutContext
  const router = useRouter()
  const searchParams = useSearchParams()

  const { basketData, basketLineItems, setBasketData, setBasketLineItems, setBasketPromotionCodes, setCurrentPayment } =
    useUbStorefrontState()

  useEffect(() => {
    const redirect = searchParams.get("redirect")
    const orderId = searchParams.get("orderId")
    if (redirect && orderId) {
      const params: { [key: string]: string } = {}
      searchParams.forEach((value, key) => {
        params[key] = value
      })
      updateOrderAfterRedirectAction(orderId, params).then((value) => {
        if ("success" === redirect) {
          router.push("/basket/checkout/confirmation")
        } else {
          console.error(value?.message)
        }
      })
    }
  }, [searchParams, router])

  useEffect(() => {
    console.dir(basketLineItems)
    console.dir(basketData)

    if (!basketData) {
      loadCurrentBasketAction().then((basketData) => {
        if (basketData) {
          setBasketData(basketData)
        }
      })
    }
  }, [basketData, basketLineItems, setBasketData])

  useEffect(() => {
    if (basketData) {
      loadBasketLineItemsAction(
        checkoutContext.isoLocale,
        checkoutContext.isB2B,
        checkoutContext.userData,
        basketData
      ).then((lineItems) => {
        if (lineItems) {
          lineItems.sort((a, b) => (a.position > b.position ? 1 : -1))
          setBasketLineItems(lineItems)
        }
      })
    }
  }, [basketData, checkoutContext.isB2B, checkoutContext.userData, setBasketLineItems])

  useEffect(() => {
    if (basketData) {
      loadBasketPromotionCodesAction().then((promotionCodes) => {
        if (promotionCodes) {
          setBasketPromotionCodes(promotionCodes)
        }
      })
    }
  }, [basketData, checkoutContext.isB2B, setBasketPromotionCodes])

  useEffect(() => {
    if (basketData) {
      loadBasketPaymentsAction().then((payments) => {
        if (payments) {
          setCurrentPayment(payments)
        }
      })
    }
  }, [basketData, checkoutContext.isB2B, setCurrentPayment])

  return <></>
}
