"use client"

import React, { useState } from "react"
import Link from "next/link"
import { XCircleIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { ExtendedAddressRO } from "@/lib/storefront/addresses/address"
import { CheckoutContext } from "@/lib/storefront/checkout/checkout-service"
import { Button } from "@/components/ui/components/button"
import { cn } from "@/components/ui/helpers/utils"
import EditAddress from "@/components/addresses/EditAddress"
import SelectAddress from "@/components/addresses/SelectAddress"
import { UbCheckoutAddressesViewStoryblok } from "@/components/storyblok/component-types"

interface CheckoutAddressesProps {
  blok: UbCheckoutAddressesViewStoryblok
  checkoutContext: CheckoutContext
}

export default function CheckoutAddresses({ blok, checkoutContext }: CheckoutAddressesProps) {
  const [invoiceToAddressToEdit, setInvoiceToAddressToEdit] = useState<ExtendedAddressRO>()
  const [shipToAddressToEdit, setShipToAddressToEdit] = useState<ExtendedAddressRO>()
  const [invoiceToAddressEditVisible, setInvoiceToAddressEditVisible] = useState(false)
  const [shipToAddressEditVisible, setShipToAddressEditVisible] = useState(false)

  console.log(checkoutContext) // todo remove this

  const addresses = checkoutContext.addresses
  const invoiceToAddresses = addresses.filter((address) => address.invoiceToAddress)
  const shipToAddresses = addresses.filter((address) => address.shipToAddress)
  const preferredInvoiceToAddress = checkoutContext.userData?.preferredInvoiceToAddress ?? invoiceToAddresses[0]
  const preferredShipToAddress = checkoutContext.userData?.preferredShipToAddress ?? shipToAddresses[0]
  const invoiceToAddressesSorted = invoiceToAddresses.sort((a, b) => (b.id === preferredInvoiceToAddress.id ? 1 : -1))
  const shipToAddressesSorted = shipToAddresses.sort((a, b) => (b.id === preferredShipToAddress.id ? 1 : -1))

  const handleEditInvoiceToAddress = (address: ExtendedAddressRO) => {
    //console.log(address)
    setInvoiceToAddressToEdit(address)
    setInvoiceToAddressEditVisible(true)
  }

  const handleEditShipToAddress = (address: ExtendedAddressRO) => {
    setShipToAddressToEdit(address)
    setShipToAddressEditVisible(true)
  }

  const handleSaveInvoiceToAddress = () => {}
  const handleSaveShipToAddress = () => {}

  return (
    <>
      <div className={"flex w-full flex-col gap-10"}>
        <div className={"flex flex-col gap-2"}>
          <h2 className={"text-lg font-bold"}>{blok.title_invoice_to_address}</h2>
          <SelectAddress
            initialAddress={preferredInvoiceToAddress}
            addresses={invoiceToAddressesSorted}
            onEdit={handleEditInvoiceToAddress}
          />
        </div>
        <div className={"flex flex-col gap-2"}>
          <h2 className={"text-lg font-bold"}>{blok.title_ship_to_address}</h2>
          <SelectAddress
            initialAddress={preferredShipToAddress}
            addresses={shipToAddressesSorted}
            onEdit={handleEditShipToAddress}
          />
        </div>
      </div>

      {/*invoiceToAddressModal*/}
      <div
        className={cn("fixed left-0 top-0 z-modal-level hidden h-screen w-screen bg-black/30", {
          block: invoiceToAddressEditVisible,
        })}
      >
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-sm bg-muted">
          <div className="p-6 pt-14">
            <EditAddress
              address={invoiceToAddressToEdit}
              customer={checkoutContext.customerData}
              user={checkoutContext.userData}
            />
          </div>
          <button className="group absolute right-3 top-3" onClick={() => setInvoiceToAddressEditVisible(false)}>
            <XCircleIcon className={"size-8 group-hover:text-primary"} />
          </button>
        </div>
      </div>

      {/*shipToAddressModal*/}

      <div
        className={cn("fixed left-0 top-0 z-modal-level hidden h-screen w-screen bg-black/30", {
          block: shipToAddressEditVisible,
        })}
      >
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-sm bg-muted">
          <div className="p-6 pt-14">
            <EditAddress
              address={shipToAddressToEdit}
              customer={checkoutContext.customerData}
              user={checkoutContext.userData}
            />
          </div>
          <button className="group absolute right-3 top-3" onClick={() => setShipToAddressEditVisible(false)}>
            <XCircleIcon className={"size-8 group-hover:text-primary"} />
          </button>
        </div>
      </div>
    </>
  )
}
