"use client"

import React, { useMemo } from "react"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { Input, Label, Textarea } from "@/components/ui/components"
import { UbCheckoutAdditionalBasketDataStoryblok } from "@/components/storyblok/component-types"

interface CheckoutAdditionalBasketDataClientProps {
  blok: UbCheckoutAdditionalBasketDataStoryblok
}

const MAX_COMMENT_LENGTH = 1000

export default function CheckoutAdditionalBasketDataClient(props: CheckoutAdditionalBasketDataClientProps) {
  const { customOrderId, setCustomOrderId, messageToMerchant, setMessageToMerchant } = useUbStorefrontState()

  function changeCustomOrderId(event) {
    const { value } = event.target
    setCustomOrderId(value)
  }

  function changeMessageToMerchant(event) {
    const { value } = event.target
    if (value && value.length <= MAX_COMMENT_LENGTH) {
      setMessageToMerchant(value)
    }
  }

  const messageToMerchantCount = useMemo(() => {
    return MAX_COMMENT_LENGTH - messageToMerchant.length
  }, [messageToMerchant])

  return (
    <>
      <div className={"flex flex-col gap-2"}>
        <div>
          <Label htmlFor={"internalOrderId"}>Bestell-ID</Label>
          <Input type="text" name="internalOrderId" value={customOrderId} onChange={changeCustomOrderId} />
          <p className={"text-sm text-secondary-foreground"}>{props.blok.message_custom_order_id}</p>
        </div>

        <div>
          <Label htmlFor={"internalOrderComment"}>Nachricht an UDOBÄR</Label>
          <Textarea name="internalOrderComment" value={messageToMerchant} onChange={changeMessageToMerchant} />
          <p className={"text-sm text-secondary-foreground"}>
            {(props.blok.message_custom_order_comment ?? "").replace("$count", "" + messageToMerchantCount)}
          </p>
        </div>
      </div>
    </>
  )
}
