interface CreditCheckConsentProps {
  checked: boolean
  onChange: any
  show: boolean | undefined
  text: string | undefined
  showRequired: boolean | undefined
  requiredText: string | undefined
}

export default function CreditCheckConsent(props: CreditCheckConsentProps) {
  return (
    props.show && (
      <div className="flex flex-row items-start gap-2 text-xs">
        <input
          aria-label={"credit_check_consent"}
          className={"checked:bg-primary hover:checked:bg-primary focus:ring-0"}
          name={"credit_check_consent"}
          type="checkbox"
          checked={props.checked}
          onChange={props.onChange}
        />
        <label htmlFor={"credit_check_consent"}>
          <div className={"flex flex-col gap-2"}>
            <div>{props.text}</div>
            <div>{props.showRequired && <p className={"text-xs text-red-500"}>{props.requiredText}</p>}</div>
          </div>
        </label>
      </div>
    )
  )
}
