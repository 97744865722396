"use client"

import React from "react"
import { useRouter } from "next/navigation"
import { createOrderFromCurrentBasketAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { CheckoutContext } from "@/lib/storefront/checkout/checkout-service"
import { Button } from "@/components/ui/components"
import { UbCheckoutReviewCreateOrderButtonStoryblok } from "@/components/storyblok/component-types"

interface CheckoutReviewCreateOrderButtonProps {
  checkoutContext: CheckoutContext
  blok: UbCheckoutReviewCreateOrderButtonStoryblok
}

export default function CheckoutReviewCreateOrderButton(props: CheckoutReviewCreateOrderButtonProps) {
  const router = useRouter()
  const isB2c = !props.checkoutContext.isB2B

  const { customOrderId, messageToMerchant, setCreatedOrder } = useUbStorefrontState()

  function createOrder() {
    createOrderFromCurrentBasketAction(isB2c, customOrderId, messageToMerchant).then((value) => {
      const createdOrder = value?.orderData
      console.dir(createdOrder, { depth: 10 })
      if (createdOrder) {
        setCreatedOrder(createdOrder)
        router.push("/basket/checkout/confirmation")
      } else {
        console.error(value?.message)
      }
    })
  }

  return (
    <>
      <Button
        onClick={createOrder}
        size={"defaultNoGrow"}
        variant={"default"}
        className={"bg-primary py-0 font-normal text-white"}
      >
        {"Kostenpflichtig Bestellen"}
      </Button>
    </>
  )
}
