"use client"

import React, { PropsWithChildren } from "react"
import { createQuoteRequestAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { LinkRO } from "@ub/quoting-client"
import { FileDown, NotebookPenIcon } from "lucide-react"

import { UserData } from "@/lib/intershop/interfaces/user/user"
import { Button } from "@/components/ui/components"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/components/tooltip"
import { UbBasketActionsStoryblok } from "@/components/storyblok/component-types"

interface BasketActionsProps extends PropsWithChildren {
  blok: UbBasketActionsStoryblok
  isB2B: boolean
  customerData?: UserData
  userData?: UserData | undefined

  tooltip_content: React.ReactNode
}

export default function BasketActions(props: BasketActionsProps) {
  const { basketLineItems, setIsLoading } = useUbStorefrontState()

  function createQuoteRequest() {
    setIsLoading(true)
    createQuoteRequestAction(props.customerData, props.userData).then((result: LinkRO) => {
      setIsLoading(false)
      window.location.href = props.blok.request_quote_path + "&QuoteUUID=" + result.title
    })
  }

  return (
    <div className={"flex flex-row flex-wrap justify-start gap-2"}>
      {basketLineItems && (
        <>
          {props.isB2B && props.blok && (
            <a className={""} href={props.blok["save_pdf_path"]}>
              <Button className={""} variant={"outline_secondary"} size={"defaultNoGrow"}>
                <div className={"flex w-full flex-row items-center justify-between gap-1"}>
                  <p>{props.blok["label_save_as_pdf"]}</p>
                  <FileDown />
                </div>
              </Button>
            </a>
          )}

          {props.isB2B && (
            <>
              {props.userData ? (
                <>
                  <Button className={""} variant={"outline_secondary"} size={"defaultNoGrow"}>
                    <div className={"flex flex-row items-center justify-between gap-1"} onClick={createQuoteRequest}>
                      <p>{props.blok["label_request_quote"]}</p>
                      <NotebookPenIcon />
                    </div>
                  </Button>
                </>
              ) : (
                <>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button className={""} variant={"outline_secondary"} size={"defaultNoGrow"}>
                          <div className={"flex flex-row items-center justify-between gap-1"}>
                            <p>{props.blok["label_request_quote"]}</p>
                            <NotebookPenIcon />
                          </div>
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent className={"p-0"}>
                        <div>{props.children}</div>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}
